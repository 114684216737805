<template>
  <div class="exchange-wrap">
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="warp">
        <div class="banner">
          <img src="../../../assets/images/coupon-banner.jpg">
        </div>
        <div class="tab-list">
          <div class="tab">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                {{category_name}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item" v-for="(item,index) in typeList" :key="index">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="tab record" @click="historyClick">兑换记录 <i class="iconfont icon-enter"></i> </div>
        </div>

        <div class="coupon-list" v-if="exchangeList.length > 0">
          <div class="coupon" v-for="(item,index) in exchangeList" :key="index" @click="goodsClick(item.product_id)">
            <img :src="item.original_image">
            <div class="coupon-warp">
              <div class="title">{{item.name}}</div>
              <div class="integral-warp">
                <i class="iconfont icon-jifen"></i>
                <div class="integral">{{item.points}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="coupon-list not" v-else>
          <div class="tip">暂无可兑换商品</div>
        </div>

        <el-pagination layout="prev, pager, next" :total="total_exchange" :page-size="per_page" :current-page="page" @current-change="changePage" :hide-on-single-page="true" class="pagination">
        </el-pagination>
      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
import utils from '@/common/utils'

export default { 
  data () {
    return {
      category_id: 0,
      category_name: "",
      typeList: [],
      exchangeList: [],
      page: 1,
      per_page: 8,
      total_exchange: 0,
    }
  },
  components: {
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    category_id: function (e) {
      if (e) {
        this.getGoodsList();
      }
    },
    page: function (e) {
      if (e) {
        this.getGoodsList();
      }
    }
  },
  created () {
    this.getCategories();
  },
  methods: {
    getCategories: function () {
      let that = this;

      that.$axios.get(that.ocApi + 'points/categories', {})
        .then(res => {
          if (res.data.status == 1) {
            that.typeList = res.data.data.children
            that.category_id = res.data.data.children[0].category_id
            that.category_name = res.data.data.children[0].name
          }
        })
    },
    getGoodsList: function () {
      let that = this;
      let category_id = that.category_id;
      let page = that.page;
      let per_page = that.per_page;

      that.$axios.get(that.ocApi + 'points/products', {
        params: {
          per_page,
          category_id,
          page
        }
      })
        .then(res => {
          that.total_exchange = res.data.total
          that.exchangeList = res.data.data
        })
    },
    handleCommand (command) {
      this.category_id = command.category_id
      this.category_name = command.name
      this.page = 1
    },
    changePage (page) {
      this.page = page
    },
    goodsClick: utils.debounce(function (id) {
      this.$router.push({ name: 'exchangeDetail', params: { goodsId: id } });
    }, 500, true),
    historyClick: utils.debounce(function () {
      this.$router.push({ name: 'exchangeHistory' });
    }, 500, true),
  },
}
</script>

<style lang="scss" scoped>
.exchange-wrap {
  .el-dropdown-link {
    cursor: pointer;
  }
  .pagination {
    text-align: center;
    padding: 20px 0;
    &.el-pagination {
      :deep(button:disabled) {
        background-color: #f7f7f7;
      }
      :deep(.btn-next),
      :deep(.btn-prev){
        background-color: #f7f7f7;
      }
      :deep(.el-pager li){
        background-color: #f7f7f7;
      }
    }
  }

  .content {
    background-color: #f7f7f7;
    padding-top: 114px;
    min-height: calc(100vh - 340px);
  }
  .warp {
    width: 1200px;
    margin: auto;
  }
  .warp .banner {
    width: 100%;
  }
  .warp .banner img {
    width: 100%;
  }
  .warp .tab-list {
    margin-top: 30px;
    margin-bottom: 10px;
    height: 33px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
  }
  .warp .tab-list .tab {
    color: #999999;
    font-size: 14px;
    padding: 10px 0;
    line-height: 14px;
  }
  .warp .tab-list .tab.record {
    cursor: pointer;
    color: #00cb87;
  }
  .warp .coupon-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    &.not {
      justify-content: center;
      align-items: center;
      padding: 50px 0;
    }
  }

  .warp .coupon-list .coupon {
    width: 24%;
    background-color: #fff;
    margin-bottom: 20px;
    margin-right: 16px;
  }
  .warp .coupon-list .coupon:nth-of-type(4n) {
    margin-right: 0;
  }
  .warp .coupon-list .coupon img {
    width: 100%;
  }
  .warp .coupon-list .coupon .coupon-warp {
    width: 100%;
    padding: 25px 16px 30px;
    box-sizing: border-box;
  }
  .warp .coupon-list .coupon .coupon-warp .title {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
  }
  .warp .coupon-list .coupon .coupon-warp .integral-warp {
    font-size: 14px;
    color: #999999;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
  }
  .warp .coupon-list .coupon .coupon-warp .integral-warp .icon-jifen {
    font-size: 13px;
    margin-right: 10px;
  }
}
</style>